import React from "react"
import { withTranslation } from "react-i18next"
import "./AboutAcexis.scss"
import { Link } from "gatsby"
const AboutAcexis = props => {
  const { t } = props
  return (
    <div className="client-cta client-cta--about-acexis">
      <div className="client-cta__text-area client-cta__text-area--about-acexis">
        <h2>{t("src.pages.clientPage.about.title")}</h2>
        <p className="client-cta__white">
          {t("src.pages.clientPage.about.content")}
        </p>
        <Link className="get-touch btn" to="/contactus" onClick={props.clicked}>
          {t("src.pages.clientPage.about.contactUs")}
        </Link>
      </div>
    </div>
  )
}

export default withTranslation()(AboutAcexis)
