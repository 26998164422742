import React from "react"
import { withTranslation } from "react-i18next"
import "./clientStats.scss"
import backgroundImg from "../../../assets/img/media/clients/bg-stats-clients.jpg"

const ClientStats = props => {
  const { t } = props
  return (
    <div className="client-stats">
      <div className="bg-stat-client">
        <img className="bg-img" src={backgroundImg} alt="Clients" />
      </div>
      <div className="container">
        <h2 className="stats__title">
          {" "}
          {t("src.pages.clientPage.stats.title")}
        </h2>
        <p className="stats__lead">{t("src.pages.clientPage.stats.content")}</p>
        <div className="stats__wrapper">
          {t("src.pages.clientPage.stats.data").map((item, index) => (
            <div className="stats__item" key={index}>
              <div className="stats__content">
                <p className="stats__number stats__number--massive">
                  {item.number}
                </p>
                <h3 className="stats__heading">{item.type}</h3>
                <p className="stats__text">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(ClientStats)
