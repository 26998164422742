import React, { Component } from "react"
import ClientsPageTitle from "./clientsPageTitle/clientsPageTitle"
import TalkToOurTeam from "../homepageInnos/about/TalkToOurTeam"
import AboutAcexis from "./aboutAcexis/AboutAcexis"
import ClientStats from "./clientStats/ClientStats"
import ClientsExploreIcon from "./clientsExploreIcon/ClientsExploreIcon"
import ClientsRepresent from "./clientsRepresent/ClientsRepresent"
import MoreSuccessButton from "./MoreSuccessButton/MoreSuccessButton"
import ClientsTakeOn from "./clientsTakeOn/ClientsTakeOn"

class ClientsPage extends Component {
  render() {
    return (
      <>
        <ClientsPageTitle />
        <ClientStats />
        <ClientsTakeOn />
        <TalkToOurTeam />
        <ClientsExploreIcon />
        <ClientsRepresent />
        <MoreSuccessButton />
        <AboutAcexis />
      </>
    )
  }
}

export default ClientsPage
