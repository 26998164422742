import React from "react"
import { withTranslation } from "react-i18next"
import { ChevronRight } from "react-feather"
import { Link } from "gatsby"

import "./moreSuccessButton.scss"

const MoreSuccessButton = props => {
  const { t } = props
  return (
    <div className="clients__more-success-button column column--flex-center">
      <div className="coverTalk">
        <Link
          to="/"
          className="button button--clear button--blue"
          onClick={props.clicked}
        >
          {t("src.pages.clientPage.moreSuccessStories")}
        </Link>
        <ChevronRight href="/" onClick={props.clicked} />
      </div>
    </div>
  )
}

export default withTranslation()(MoreSuccessButton)
