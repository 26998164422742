import React from "react"
import { withTranslation } from "react-i18next"
import AboutLogo from "../../homepageInnos/about/aboutLogo.components"
import "./clientsTakeOn.scss"

const ClientsTakeOn = props => {
  const { t } = props
  return (
    <section id="" className="clients__take-on cemetery">
      <div className="container" style={{ opacity: 1 }}>
        <div
          className="cemetery__title"
          style={{ opacity: 1, marginBottom: "30px" }}
        >
          <h2> {t("src.pages.clientPage.takeOn.title")}</h2>
          <p className="discription">
            {t("src.pages.clientPage.takeOn.descriptions")}
          </p>
        </div>
        <AboutLogo />
      </div>
    </section>
  )
}

export default withTranslation()(ClientsTakeOn)
