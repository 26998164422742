import React from "react"
import { withTranslation } from "react-i18next"
import "./clientsExploreIcon.scss"

const ClientsExploreIcon = props => {
  const { t } = props
  return (
    <div className="client__explore_icon">
      <div className="container">
        <h2>{t("src.pages.clientPage.explorer.title")}</h2>
        <ul>
          <li>
            <div className="software-engineering-icon client-explore-icon"></div>
            <h3>{t("src.pages.clientPage.explorer.software")}</h3>
          </li>
          <li>
            <div className="intelligent-consulting-icon client-explore-icon"></div>
            <h3>{t("src.pages.clientPage.explorer.iC")}</h3>
          </li>
          <li>
            <div className="hitech-solution-icon client-explore-icon"></div>
            <h3>{t("src.pages.clientPage.explorer.hiTech")}</h3>
          </li>
          <li>
            <div className="iot-ai-icon client-explore-icon"></div>
            <h3>{t("src.pages.clientPage.explorer.ioT")}</h3>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default withTranslation()(ClientsExploreIcon)
