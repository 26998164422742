import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"
import "./clientsRepresent.scss"
import logo1 from "../../../assets/img/media/clients/represent/logo/cirruspath.svg"
import logo2 from "../../../assets/img/media/clients/represent/logo/eurostar.svg"
import logo3 from "../../../assets/img/media/clients/represent/logo/harley-davidson.svg"
import logo4 from "../../../assets/img/media/clients/represent/logo/ernst-young.svg"
import RightArrow from "../../../assets/img/media/clients/represent/right-arrow.svg"

const ClientsRepresent = props => {
  const { t } = props
  return (
    <div className="clients__clients-represent">
      <div className="container">
        <div className="clients__grid">
          <div className="clients__item">
            <div className="clients__content">
              <div className="clients__media">
                <div className="logo1 clients__media-inner" />
                <Link to="/">
                  <img className="clients__logo" src={logo1} alt="Cirruspath" />
                </Link>
              </div>
              <div className="clients__text">
                <span className="clients__tag">
                  {t("src.pages.clientPage.rePresent.cirrus.tag")}
                </span>
                <Link to="/">
                  <h3 className="clients__title">
                    {t("src.pages.clientPage.rePresent.cirrus.title")}
                  </h3>
                </Link>
                <p className="clients__description">
                  {t("src.pages.clientPage.rePresent.cirrus.descriptions")}
                </p>
                <Link to="/" className="clients__link--more">
                  {t("src.pages.clientPage.rePresent.viewMore")}
                  <img
                    className="right-arrow"
                    src={RightArrow}
                    alt="right-arrow"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="clients__item">
            <div className="clients__content">
              <div className="clients__media">
                <div className="logo2 clients__media-inner" />
                <Link to="/">
                  <img className="clients__logo" src={logo2} alt="Eurostar" />
                </Link>
              </div>
              <div className="clients__text">
                <span className="clients__tag">
                  {t("src.pages.clientPage.rePresent.euroStar.tag")}
                </span>
                <Link to="/">
                  <h3 className="clients__title">
                    {t("src.pages.clientPage.rePresent.euroStar.title")}
                  </h3>
                </Link>
                <p className="clients__description">
                  {t("src.pages.clientPage.rePresent.euroStar.descriptions")}
                </p>
                <Link to="/" className="clients__link--more">
                  {t("src.pages.clientPage.rePresent.viewMore")}
                  <img
                    className="right-arrow"
                    src={RightArrow}
                    alt="right-arrow"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="clients__item">
            <div className="clients__content">
              <div className="clients__media">
                <div className="logo3 clients__media-inner" />
                <Link to="/">
                  <img
                    className="clients__logo"
                    src={logo3}
                    alt="Harley-Davidson"
                  />
                </Link>
              </div>
              <div className="clients__text">
                <span className="clients__tag">
                  {t("src.pages.clientPage.rePresent.harleyDavidson.tag")}
                </span>
                <Link to="/">
                  <h3 className="clients__title">
                    {t("src.pages.clientPage.rePresent.harleyDavidson.title")}
                  </h3>
                </Link>
                <p className="clients__description">
                  {t(
                    "src.pages.clientPage.rePresent.harleyDavidson.descriptions"
                  )}
                </p>
                <Link to="/" className="clients__link--more">
                  {t("src.pages.clientPage.rePresent.viewMore")}
                  <img
                    className="right-arrow"
                    src={RightArrow}
                    alt="right-arrow"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="clients__item">
            <div className="clients__content">
              <div className="clients__media">
                <div className="logo4 clients__media-inner" />
                <Link to="/">
                  <img
                    className="clients__logo"
                    src={logo4}
                    alt="Ernst-Young"
                  />
                </Link>
              </div>
              <div className="clients__text">
                <span className="clients__tag">
                  {t("src.pages.clientPage.rePresent.ernstAndYoung.tag")}
                </span>
                <Link to="/">
                  <h3 className="clients__title">
                    {t("src.pages.clientPage.rePresent.ernstAndYoung.title")}
                  </h3>
                </Link>
                <p className="clients__description">
                  {t(
                    "src.pages.clientPage.rePresent.ernstAndYoung.descriptions"
                  )}
                </p>
                <Link to="/" className="clients__link--more">
                  {t("src.pages.clientPage.rePresent.viewMore")}
                  <img
                    className="right-arrow"
                    src={RightArrow}
                    alt="right-arrow"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(ClientsRepresent)
