import React from "react"
//import {Link} from "gatsby"
import Layout from "../components/layout/layout.higherComponent"
import Head from "../components/head"
import ClientsPage from "../components/clients/clientsPage";

const Clients = () => {
  return (
    <Layout>
      <Head title="Clients" />
      <ClientsPage />
    </Layout>
  )
}

export default Clients
