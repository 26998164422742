import React from "react"
import { withTranslation } from "react-i18next"
// import {dataClientsTitle} from '../../../data/Clients/dataClients'
import "./clientsPageTitle.scss"
const ClientsPageTitle = props => {
  const { t } = props
  return (
    <section className="hero hero--600 bgClients">
      <div className="container">
        <div className="hero__content">
          <h1 className="title title--uppercase">
            <span className="title__preheader title__preheader--tag">
              {t("src.pages.clientPage.title")}
            </span>
            <span>{t("src.pages.clientPage.subtitle1")}</span>
            <span>{t("src.pages.clientPage.subtitle2")}</span>
            <span>{t("src.pages.clientPage.subtitle3")}</span>
          </h1>
          <p className="hero__lead">{t("src.pages.clientPage.descriptions")}</p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(ClientsPageTitle)
